@font-face {
  font-family: "metropolis-thin";
  /* src: url("./assets/fonts/Metropolis-Regular.ttf") format("ttf"); */
  src: local('metropolis-thin'), url("./assets/fonts/Metropolis-Regular.ttf") format("ttf");
  /* src: local('metropolis-thin'), url("./assets/fonts/metropolis.thin.otf") format("otf"); */
  /* Add other formats as you see fit */
}
@font-face {
  font-family: "metropolis-bold";
  /* src: url("./assets/fonts/Metropolis-Bold.ttf") format("ttf"); */
  src: local('metropolis-bold'), url("./assets/fonts/Metropolis-Bold.ttf") format("ttf");
  /* src: local('metropolis-bold'), url("./assets/fonts/metropolis.bold.otf") format("otf"); */
  /* Add other formats as you see fit */
}
body {
  margin: 0;
  font-family: 'metropolis-thin', 'metropolis-bold';
  /* font-family: 'Edu SA Beginner', cursive; */
  font-family: 'Metropolis';
}

code {
  font-family: 'metropolis-thin', 'metropolis-bold';
}

input:focus-visible {
  outline-offset: unset !important;
  outline: none;
}
